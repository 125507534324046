

////////////////////////////////////
////Alpineのインポートと明示的な起動//////
////////////////////////////////////
import Alpine from "alpinejs";

window.Alpine = Alpine;
Alpine.start();


////////////////////////////////////
////splideのインポート//////
////////////////////////////////////
import Splide from '@splidejs/splide';

const splideElement = document.querySelector(".splide");
if (splideElement) {
new Splide(".splide", {
  arrows: false,
  type   : 'loop',
  classes: {
    pagination: "splide__pagination",
    page: "splide__pagination__page",
  },
}).mount();
}



////////////////////////////////////
////360px 未満は JS で viewport を固定する//////
////////////////////////////////////
!(function () {
  const viewport = document.querySelector('meta[name="viewport"]');
  function switchViewport() {
    const value =
      window.outerWidth > 360
        ? 'width=device-width,initial-scale=1'
        : 'width=360';
    if (viewport.getAttribute('content') !== value) {
      viewport.setAttribute('content', value);
    }
  }
  addEventListener('resize', switchViewport, false);
  switchViewport();
})();

